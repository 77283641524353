<template>
  <div class="order">
    <van-sticky>
      <div class="search">
        <div class="serch-box">
          <van-dropdown-menu class="menu">
            <van-dropdown-item
              v-model="deviceSn"
              :options="equipOptions"
              @change="changeDeviceSn"
            />
            <van-dropdown-item
              v-if="userType != 2"
              v-model="store"
              :options="storeOptions"
              @change="changeStore"
            />
          </van-dropdown-menu>
          <!-- <van-dropdown-menu>
          </van-dropdown-menu> -->
        </div>
        <van-dropdown-menu
          style="margin: 0 15px; margin-top: 5px"
          class="menu"
        >
          <van-dropdown-item
            title="交易时间"
            ref="item"
            :title-class="isTime ? 'timeactive' : ''"
          >
            <div id="calendar">
              <van-calendar
                ref="calendar"
                :show-confirm="false"
                :poppable="false"
                :min-date="minDate"
                v-model="show"
                type="range"
                position="top"
                :close-on-click-overlay="false"
                :show-title="false"
                :style="{ height: '330px' }"
                row-height="30"
              >
                <div slot="footer" class="footer">
                  <van-button class="btn-style reset" @click="onReset()"
                    >重置</van-button
                  >
                  <van-button
                    class="btn-style confirm"
                    @click="onCalendarConfirm()"
                    >确定</van-button
                  >
                </div>
              </van-calendar>
            </div>
          </van-dropdown-item>
          <van-dropdown-item
            v-model="payType"
            :options="payOptions"
            @change="changePay"
            :title-class="isPay ? 'payactive' : ''"
          />
          <van-dropdown-item
            v-model="orderStatus"
            :options="statusOptions"
            @change="changeStatus"
            :title-class="isStatu ? 'statusactive' : ''"
          />
        </van-dropdown-menu>
      </div>
    </van-sticky>
    <div v-if="isTime && statistics">
      <div class="flex-x-around bg-main time-wrap color-white">
        <div class="statistics-item text-center">{{ formData.startDate }}</div>
        <div>-</div>
        <div class="statistics-item text-center">{{ formData.endDate }}</div>
      </div>
      <div class="flex-x-around bg-main sub-banner">
        <div class="statistics-item color-white">
          <div class="text-12">交易笔数</div>
          <div class="m-t-10 text-16">{{ statistics.transactionNum || 0 }}</div>
        </div>
        <div class="statistics-item color-white">
          <div class="text-12">交易金额</div>
          <div class="m-t-10 text-16">
            {{ statistics.transactionMoney || 0 }}
          </div>
        </div>
        <div class="statistics-item color-white">
          <div class="text-12">交易手续费</div>
          <div class="m-t-10 text-16">
            {{ statistics.transactionHandlingFee || 0 }}
          </div>
        </div>
      </div>
      <div class="flex-x-around bg-main sub-banner">
        <div class="statistics-item color-white">
          <div class="text-12">退款笔数</div>
          <div class="m-t-10 text-16">{{ statistics.refundNum || 0 }}</div>
        </div>
        <div class="statistics-item color-white">
          <div class="text-12">退款金额</div>
          <div class="m-t-10 text-16">{{ statistics.refundAmount || 0 }}</div>
        </div>
        <div class="statistics-item color-white">
          <div class="text-12">实际到账</div>
          <div class="m-t-10 text-16">{{ statistics.actuallyArrive || 0 }}</div>
        </div>
      </div>
    </div>
    <div v-if="lists.length > 0">
      <van-list
        v-model="loading"
        :finished="finished"
        :immediate-check="false"
        :offset="10"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="order-list">
          <div v-for="(item, index) in lists" :key="index" class="item">
            <div class="item-top flex-x-between flex-xy-center">
              <div class="top-left flex-x-start flex-xy-center">
                <img src="~assets/images/common/line.png" alt />
                <div v-if="item.deviceNo">设备:{{ item.deviceNo }}</div>
                <div v-else>二维码牌付款</div>
              </div>
              <div
                class="top-right"
                :class="item.orderStatus === 2 ? 'fail' : ''"
              >
                {{ orderStatusName(item.orderStatus) }}
              </div>
            </div>
            <div class="item-center">
              <div class="flex-x-between">
                <span>支付方式:</span>
                <p>{{ item.payType | payWay }}</p>
              </div>
              <div class="flex-x-between">
                <span>交易时间:</span>
                <p>{{ item.createTime }}</p>
              </div>
              <div class="flex-x-between">
                <span>交易金额:</span>
                <p>{{ item.amount }}</p>
              </div>
              <template v-if="userType != 2">
                <div
                  v-if="isRefundShown(item)"
                  class="row-between-center"
                  style="margin: 0"
                >
                  <div></div>
                  <div
                    class="btn-refund"
                    style="margin: 0"
                    @click="onRefund(item)"
                  >
                    申请退款
                  </div>
                </div>
              </template>
            </div>
            <div class="item-footer">
              <div class="flex-x-between">
                <span class="order-prefix">订单号:</span>
                <p class="order-no">{{ item.orderNo }}</p>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
    <van-empty description="暂无数据" v-else />
    <foot-tabbar />
    <van-popup
      v-model="showRefundConfirmPop"
      closeable
      round
      style="width: 350px"
    >
      <div class="pop-content">
        <div class="pop-title">提示</div>
        <div class="input-wrap">
          <van-field
            v-model="refundConfirmPassword"
            placeholder="请输入登录密码"
            type="password"
          />
        </div>
        <div class="btn-wrap">
          <van-button
            class="btn-cancel"
            round
            block
            @click="showRefundConfirmPop = false"
            >取消</van-button
          >
          <van-button class="btn-confirm" round block @click="onRefundConfirm"
            >确定</van-button
          >
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import FootTabbar from "@/components/FootTabbar";
import {
  transactions,
  transactionDeviceSN,
  transactionStatistics,
  transactionRefund,
  getMerchantStoreList
} from "api/common";
import { Dialog } from "vant";
import { getCookie } from "utils/cookie";

export default {
  name: "BindWx",
  components: {
    [Dialog.Component.name]: Dialog.Component,
    FootTabbar
  },
  data() {
    return {
      showRefundConfirmPop: false,
      isTime: false,
      refundConfirmPassword: "", // 退款确认密码
      refundItem: "",
      isPay: false,
      isStatu: false,
      deviceSn: "请选择设备SN",
      store: "请选择门店",
      operationMethod: "支付方式",
      payType: "支付方式",
      orderStatus: "交易状态",
      formData: {
        merchantId: "",
        payType: "", //支付方式
        startDate: "",
        endDate: "",
        deviceSn: "", //设备sn
        orderStatus: "", //交易状态
        merchantStoreId: "",
        pageSize: 10,
        pageNum: 1
      },
      equipOptions: [{ text: "请选择设备SN", value: "请选择设备SN" }],
      storeOptions: [{ text: "请选择门店", value: "请选择门店" }],
      payOptions: [
        { text: "支付方式", value: "支付方式" },
        { text: "微信支付", value: "1" },
        { text: "支付宝支付", value: "2" },
        { text: "云闪付", value: "3" }
      ],
      statusOptions: [
        { text: "交易状态", value: "交易状态" },
        { text: "交易成功", value: "1" },
        { text: "交易失败", value: "2" }
      ],
      lists: [],
      statusName: {
        0: "等待支付",
        1: "支付成功",
        2: "退回资金",
        3: "支付取消",
        4: "申请退款中"
      },
      statistics: "",
      date: "",
      show: true,
      minDate: new Date(2020, 12, 31),
      total: 0, //总共的数据条数
      loading: false,
      finished: false,
      userType: localStorage.getItem("userType")
    };
  },
  filters: {
    status(val) {
      if (val === "1") {
        return "交易成功";
      } else if (val === "2") {
        return "交易失败";
      }
    },
    payWay(val) {
      if (val == 1) {
        return "微信支付";
      } else if (val == 2) {
        return "支付宝支付";
      } else if (val == 3) {
        return "云闪付";
      } else {
        return "-";
      }
    }
  },
  computed: {
    orderStatusName() {
      // 订单状态  0等待支付 1支付成功 2退回资金 3支付取消 4申请退款中
      return status => this.statusName[status];
    },
    // 退款只能退今日 支付成功的订单
    isRefundShown() {
      return item => {
        const isPaySuccess = item.orderStatus == 1;
        if (isPaySuccess) {
          const payDate = new Date(item.createTime);
          const nowDate = new Date();

          const days =
            (nowDate.getTime() - payDate.getTime()) / 3600 / 24 / 1000;
          return payDate.getDate() == nowDate.getDate() && days <= 1;
        }

        return isPaySuccess;
      };
    }
  },
  created() {
    this.formData.merchantId = JSON.parse(localStorage.getItem("payInfo")).id;
    this.getTransactions();
    this.getSn();
    this.getStoreList();
  },
  methods: {
    //点击退款按钮
    onRefund(item) {
      this.refundConfirmPassword = "";
      this.refundItem = item;
      this.showRefundConfirmPop = true;
    },
    onRefundConfirm() {
      if (!this.refundConfirmPassword) {
        this.$toast.fail("请输入您的登录密码");
      }
      if (this.refundConfirmPassword != getCookie("userPwd")) {
        this.$toast.fail("您的登录密码不正确");
        return;
      }

      this.showRefundConfirmPop = false

      transactionRefund(this.refundItem.id).then(res => {
        this.refundItem = ''
        this.$toast({
          type: "success",
          message: `申请退款${res.code == 200 ? "成功" : "失败"}`
        });
        if (res.code == 200) {
          this.init();
          this.getTransactions();
        }
      }).catch(() => this.refundItem = '')
    },
    // 获取sn列表
    getSn() {
      transactionDeviceSN().then(res => {
        if (res.code === 200) {
          for (let i = 0; i < res.data.length; i++) {
            let province_list = {};
            province_list.text = res.data[i].deviceNo;
            province_list.value = res.data[i].deviceNo;
            this.equipOptions.push(province_list);
          }
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
    // 获取统计交易
    getTransactions() {
      transactions(this.formData).then(res => {
        if (res.code === 200) {
          let rows = res.data.list; //请求返回当页的列表
          this.loading = false;
          this.total = res.data.total;
          if (rows == null || rows.length === 0) {
            // 加载结束
            this.finished = true;
            return;
          }

          // 将新数据与老数据进行合并
          this.lists = this.lists.concat(rows);

          //如果列表数据条数>=总条数，不再触发滚动加载
          if (this.lists.length >= this.total) {
            this.finished = true;
          }
        } else {
          this.$toast.fail(res.msg);
        }
      });

      if (this.formData.startDate && this.formData.endDate) {
        const isSameDay = this.formData.startDate == this.formData.endDate;
        const params = {
          orderStatus: this.formData.orderStatus,
          startCreateTime: this.formData.startDate + " 00:00:00",
          endCreateTime:
            this.formData.endDate + (isSameDay ? " 23:59:59" : " 00:00:00"),
          payType: this.formData.payType,
          deviceNo: this.formData.deviceSn
        };
        transactionStatistics(params).then(res => {
          this.statistics = res.data;
        });
      }
    },
    onLoad() {
      this.formData.pageNum++;
      this.getTransactions();
    },
    formatDate(date) {
      if (!date) return "";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;
      return year + "-" + month + "-" + day;
    },
    // 条件搜索，数据初始化
    init() {
      this.lists = [];
      this.formData.pageNum = 1;
      this.loading = false;
      this.finished = false;
    },
    onCalendarConfirm() {
      this.onConfirm(this.$refs.calendar.currentDate);
    },
    // 点击时间确认按钮
    onConfirm(date) {
      console.log("onConfirm", date);
      this.init();
      const start = date[0];
      const end = date[1] || start;
      this.formData.startDate = this.formatDate(start);
      this.formData.endDate = this.formatDate(end);
      this.getTransactions();
      this.$refs.item.toggle();
      this.isTime = true;
    },
    //点击重置按钮
    onReset() {
      this.init();
      this.formData.startDate = "";
      this.formData.endDate = "";
      this.getTransactions();
      this.$refs.item.toggle();
      this.isTime = false;
    },
    // 改变设备sn
    changeDeviceSn(value) {
      this.init();
      if (value == "请选择设备SN") {
        this.formData.deviceSn = "";
      } else {
        this.formData.deviceSn = value;
      }
      this.getTransactions();
    },
    changeStore(value) {
      this.init();
      if (value == "请选择门店") {
        this.formData.merchantStoreId = "";
      } else {
        this.formData.merchantStoreId = value;
      }

      this.getTransactions();
    },
    // 改变支付方式
    changePay(value) {
      this.init();
      if (value === "支付方式") {
        this.isPay = false;
        this.formData.payType = "";
        this.formData.operationMethod = "";
      } else if (value === "1") {
        this.isPay = true;
        this.formData.payType = 1;
        this.formData.operationMethod = 3;
      } else if (value === "2") {
        this.isPay = true;
        this.formData.payType = 2;
        this.formData.operationMethod = "1,2";
      } else if (value === "3") {
        this.isPay = true;
        this.formData.payType = 3;
        this.formData.operationMethod = "1,2";
      }
      this.getTransactions();
    },
    // 改变交易状态
    changeStatus(value) {
      this.init();
      if (value === "交易状态") {
        this.formData.orderStatus = "";
        this.isStatu = false;
      } else {
        this.formData.orderStatus = value;
        this.isStatu = true;
      }
      this.getTransactions();
    },
    getStoreList() {
      if (this.userType == 2) return;
      getMerchantStoreList().then(res => {
        const list = res.data.map(item => {
          return {
            text: item.merchantStoreName,
            value: item.id
          };
        });
        this.storeOptions = this.storeOptions.concat(list);
      });
    }
  }
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 539px) {
  /deep/.van-sticky--fixed {
    position: fixed;
    top: 0;
    right: none;
    left: none;
    z-index: 99;
    width: 375px;
    margin: 0 auto;
  }
  /deep/.van-overlay {
    right: none;
    left: 50%;
    width: 375px;
    margin-left: -187.5px;
  }
}
// 支付方式选中时
// /deep/.payactive .van-ellipsis {
//   color: #fd6e05 !important;
// }
// /deep/.payactive::after {
//   border-color: transparent transparent #fd6e05 #fd6e05 !important;
// }
// 交易状态选中时
// /deep/.statusactive .van-ellipsis {
//   color: #fd6e05 !important;
// }
// /deep/.statusactive::after {
//   border-color: transparent transparent #fd6e05 #fd6e05 !important;
// }
// 交易状态选中时
// /deep/.timeactive .van-ellipsis {
//   color: #fd6e05 !important;
// }
// /deep/.timeactive::after {
//   border-color: transparent transparent #fd6e05 #fd6e05 !important;
// }

.order {
  font-size: 14px;
  .search {
    background: #f9f9f9;
    .serch-box {
      background: #fff;
      overflow: hidden;
      // /deep/.van-dropdown-menu__title {
      //   width: 100%;
      //   font-size: 14px;
      // }
      /deep/.van-dropdown-menu__bar {
        margin: 15px;
        border: 1px solid #fd6e05;
        border-radius: 17px;
        height: 32px;
        padding: 0 15px;
      }
      // /deep/.van-dropdown-menu__item {
      //   justify-content: flex-start;
      // }
    }
    /deep/.van-popup--top {
      width: 345px;
      margin-left: -172.5px;
      left: 50%;
    }
    /deep/.van-dropdown-menu__title::after {
      margin-top: -6px;
      border: 4px solid;
      border-color: transparent transparent #333333 #333333;
    }
    /deep/.van-dropdown-menu__title--active::after {
      margin-top: -0px;
      border-color: transparent transparent #fd6e05 #fd6e05;
    }
    #calendar {
      /deep/.van-calendar {
        position: relative;
      }
      /deep/.van-popup--top {
        top: 125px;
      }
      /deep/.van-calendar__popup .van-popup__close-icon {
        display: none;
      }
      /deep/.van-overlay {
        display: none;
      }
    }
    // /deep/.van-calendar__day{
    //   height: 30px;
    //   width: 30px;
    //   margin: 4px 9px;
    // }
    /deep/.van-calendar__day--start,
    /deep/.van-calendar__day--end {
      // border-radius: 50%;
      background: #fd6e05;
    }
    /deep/.van-calendar__bottom-info {
      display: none;
    }
    /deep/.van-calendar__day--middle {
      color: #fd6e05;
      background: rgba(253, 110, 5, 0.13);
    }
    /deep/.van-button--danger {
      width: 46%;
      float: right;
      border: none;
      background: linear-gradient(
        89deg,
        rgba(253, 110, 5, 0.66) 10%,
        #fd6e05 81%
      );
    }
    /deep/.van-dropdown-menu__title--active {
      color: #fd6e05;
    }
    /deep/.van-dropdown-item__option--active {
      color: #fd6e05;
    }
    /deep/.van-dropdown-item__option--active .van-dropdown-item__icon {
      color: #fd6e05;
    }
  }

  .row-between-center {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
  }
  .sub-banner {
    padding: 14px 0 14px 30px;
    margin: 0 15px;
  }
  .footer {
    display: flex;
    justify-content: space-around;
    .btn-style {
      width: 100%;
      border-radius: 50px;
      margin: 10px;
    }
    .confirm {
      color: #fff;
      background: linear-gradient(
        89deg,
        rgba(253, 110, 5, 0.66) 10%,
        #fd6e05 81%
      );
    }
  }

  .text-16 {
    font-size: 16px;
  }
  .text-12 {
    font-size: 12px;
  }

  .order-list {
    margin: 15px;
    .btn-refund {
      border-radius: 50px;
      padding: 2px 7px;
      border: 1px solid gray;
    }
    .item {
      padding: 18px 15px 15px 0;
      background: #fff;
      margin-top: 15px;
      .item-top {
        .top-left {
          img {
            height: 14px;
            margin-right: 10px;
          }
        }
        .top-right {
          color: #fd6e05;
        }
        .fail {
          color: #fe4242;
        }
      }
      .item-center {
        margin: 15px 0 15px 15px;
        padding: 7px 0;
        border-top: 1px solid rgba(51, 51, 51, 00.1);
        border-bottom: 1px solid rgba(51, 51, 51, 00.1);
        div {
          margin: 8px 0;
        }
      }
      .item-footer {
        padding: 0 0 0 15px;
        span {
          color: rgba(30, 36, 49, 0.7);
          p {
            color: #1e2431;
          }
        }
      }
      .order-prefix {
        width: 60px;
      }
      .order-no {
        flex: 1;
        text-align: end;
        max-width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
.statistics-item {
  flex: 1;
}

.pop-content {
  padding: 20px 10px;
  .pop-title {
    color: #333;
    text-align: center;
  }

  .input-wrap {
    margin: 10px 0;
  }

  .btn-wrap {
    display: flex;
    align-items: center;
    margin-top: 20px;

    .btn-cancel {
      margin-right: 5px;
    }

    .btn-confirm {
      background: linear-gradient(
        89deg,
        rgba(253, 110, 5, 0.66) 10%,
        #fd6e05 81%
      );
      border-radius: 21px;
      color: #fff !important;
      font-size: 15px;
      margin-left: 5px;
    }
  }
}

.time-wrap {
  padding: 14px 0;
  margin: 0 15px;
}

.text-center {
  text-align: center;
}
</style>
